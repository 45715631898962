body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.initials-avatar {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  background-color: #24406E;
  display: inline-block;
  box-sizing: border-box;
}


.initials-avatar div {
    color: #FFFFFF;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: inherit;
    font-weight: inherit;
  }


@tailwind base;
@tailwind components;
@tailwind utilities;

